import React from 'react'
import Index from '../views/Index'
import Edit from '../views/Edit'
import Login from '../views/Login'
import { useSelector, useDispatch } from 'react-redux'
import { setShow } from '../app/store'
import { useParams } from 'react-router-dom'
import Register from '../templates/Register'
import Redirector from '../templates/Redirector'
import Profile from '../templates/Profile'

const Centralbody = (props) => {

    const user = useSelector((state)=> state.user)
    const menu = useSelector((state)=> state.menu)
    const dispatch = useDispatch()
    const {id} = useParams()
    const {url,nomeSing,nomePlur,apiModel,enableSave,enableDelete,enableNew,baseUrl,MEDIA_URL,links,additionalFilter} = props

     /* New model creation logic managed in Edit */
    return (
      <>
        {apiModel !== 'apiReference' && <div className={'hero'+(menu.show?' menu-open':'')} onClick={()=>{if(menu.show)dispatch(setShow(false))}}>
        <div className=''>
          {!user.logged_in && url !== '/register' && <Login />}
          {!user.logged_in && url === '/register' && <Register owner={''} />}
          {(user.logged_in && (url !== '/register' && url !== '/profile')) && <Index nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} enableNew={enableNew} baseUrl={baseUrl} links={links} additionalFilter={additionalFilter} />}
          {(user.logged_in && id && (url !== '/dashboard' && url !== '/register' && url !== '/profile')) && <Edit nomeSing={nomeSing} nomePlur={nomePlur} url={url} apiModel={apiModel} enableSave={enableSave} enableNew={enableNew} baseUrl={baseUrl} enableDelete={enableDelete} MEDIA_URL={MEDIA_URL} additionalFilter={additionalFilter} />}
          {(user.logged_in && url === '/register') && <Redirector/>}
          {(user.logged_in && url === '/profile') && <Profile/>}
        </div>
      </div>}

      {apiModel === 'apiReference' && <iframe className={'hero'+(menu.show?' menu-open':'')} src="https://apireference.neosidea.com/" title="API Reference frame"></iframe>}

      </>
    )
}

export default Centralbody
