import React from 'react'

const Footer = () => {
  return (
    <footer id="footer">
        <div className="container py-3 text-right">
          <div className='row'>

            <div className="col-12 col-md-6 ms-md-auto text-center text-md-start">
              Versione demo 0.9.1
            </div>
              <div className="col-12 col-md-6 me-md-auto text-center text-md-end">
                  <div className="copyright">
                      <span className='me-2'>Powered by</span> <img src={process.env.REACT_APP_LOCAL_SUBFOLDER+"/img/neosidea_footer.png"} alt="Neosidea logo" />
                  </div>
              </div>

          </div>

        </div>
    </footer>
  )
}

export default Footer
